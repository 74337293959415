<template>
  <div class="navbar-container d-flex content align-items-center justify-content-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-lg-flex mr-auto">
      <a
        class="d-flex align-items-center text-body"
        href="mailto:Assistance Agriprogress<vgrabet@invivo-group.com>?subject=Assistance%20Agriprogress"
      >
        <feather-icon
          size="16"
          icon="MailIcon"
          class="mr-50"
        />
      </a>
    </div>

    <div>{{ companyName }} <i>{{ companyExtraInfo }}</i></div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.full_name }}
            </p>
            <span class="user-status">{{ userData.role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar
} from "bootstrap-vue";
import * as Sentry from "@sentry/vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { mapState } from "vuex";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar

    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      userData: getUserData()
    };
  },
  computed: {
    ...mapState("verticalMenu", ["currentCooperative"]),
    ...mapState("farm", { farmHveAudit: "hveAudit" }),

    companyName() {
      if (!this.userData) {
        return "";
      }
      if (this.userData.type === "Farmer") {
        return this.userData?.farm.name;
      }
      if (this.userData.type === "Technician") {
        return `Coopérative : ${this.userData?.cooperative.name}`;
      }

      if (this.userData.type === "Admin") {
        return "Administrateur";
      }
      if (this.userData.type === "Industrial") {
        return `IAA : ${this.userData?.industry.name}`;
      }

      return "";
    },
    companyExtraInfo() {
      if (!this.userData || !this.currentCooperative) {
        return "";
      }
      if (this.userData.type === "Farmer") {
        return `( Coopérative : ${this.currentCooperative.name} )`;
      }

      return "";
    }
  },
  methods: {
    logout() {
      useJwt.logout()
        .finally(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          Sentry.configureScope(scope => scope.setUser(null));

          // Remove userData from localStorage
          localStorage.removeItem("userData");

          // Reset vuex modules
          this.$store.dispatch("app/resetModulesState");

          // Reset ability
          this.$ability.update(initialAbility);

          // Redirect to login page
          this.$router.push({ name: "auth-login" });
        });
    }
  }
};
</script>
