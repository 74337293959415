<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="vertical-menu-header">
      <router-link :to="{name: 'home'}">
        <b-img
          class="mb-2"
          :width="130"
          :src="logoImg"
        />
      </router-link>
      <b-nav class="mb-1">
        <b-nav-item-dropdown class="custom-dropdown">
          <!-- Using 'button-content' slot -->
          <template slot="button-content">
            <div class="custom-dropdown-button">
              <feather-icon
                size="20"
                icon="CalendarIcon"
                class="mr-1"
              />
              <span>Campagne {{ currentPeriod }}</span>
            </div>
          </template>
          <b-dropdown-item
            v-for="(period, index) in periods"
            :key="index"
            @click="currentPeriod = period"
          >
            {{ period }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>

      <b-nav
        v-if="cooperatives.length > 1"
        class="mb-2"
      >
        <b-nav-item-dropdown class="custom-dropdown">
          <!-- Using 'button-content' slot -->
          <template slot="button-content">
            <div class="custom-dropdown-button">
              <feather-icon
                size="20"
                icon="MapPinIcon"
                class="mr-1"
              />
              <span>{{ currentCooperative.name }}</span>
            </div>
          </template>
          <b-dropdown-item
            v-for="(cooperative, index) in cooperatives"
            :key="index"
            @click="currentCooperative = cooperative"
          >
            {{ cooperative.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    </div>

    <div slot="footer">
      @Agriprogress - 2021
    </div>
  </layout-vertical>
</template>

<script>
import {
  BDropdownItem, BImg, BNavItemDropdown, BNav
} from "bootstrap-vue";
import { mapState } from "vuex";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import Navbar from "../components/Navbar.vue";

export default {
  components: {
    LayoutVertical,
    Navbar,
    BDropdownItem,
    BImg,
    BNavItemDropdown,
    BNav
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require("@/assets/images/logo/logo.png")
    };
  },
  computed: {
    ...mapState("verticalMenu", ["periods", "cooperatives"]),
    currentPeriod: {
      get() { return this.$store.state.verticalMenu.currentPeriod; },
      set(val) { this.$store.commit("verticalMenu/SET_PERIOD", val); }
    },
    currentCooperative: {
      get() { return this.$store.state.verticalMenu.currentCooperative; },
      set(val) { this.$store.commit("verticalMenu/SET_COOPERATIVE", val); }
    }
  }
};
</script>
